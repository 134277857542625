import moment from 'moment'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'

import RHFInput from './RHFInput'

import IconButton from 'components/Luxkit/Button/IconButton'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineClockIcon from 'components/Luxkit/Icons/line/LineClockIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import { useAppSelector } from 'hooks/reduxHooks'
import { useRegionTimeFormat } from 'hooks/useRegionTimeFormat'
import isSafari from 'lib/web/isSafari'
import { isMobileSel } from 'selectors/configSelectors'

const TIME_FORMAT = 'HH:mm'

const generateTimes = () => {
  const times: Array<string> = []

  const m = moment().hours(0).minutes(0)

  for (let i = 0; i < 24 * 2; i++) {
    times.push(m.format(TIME_FORMAT))

    m.add('00:30')
  }

  return times
}

const times = generateTimes()

interface Props extends Omit<React.ComponentProps<typeof RHFInput>, 'type'> {
  value?: string
  hasIcon?: boolean
  showClear?: boolean
  testIdPrefix?: string
}

const TimeInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      onBlur,
      onFocus,
      name,
      hasIcon = true,
      showClear = true,
      testIdPrefix,
      ...rest
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => inputRef.current!)

    const [isOpen, setIsOpen] = useState(false)
    const { watch, setValue } = useFormContext()
    const time = watch(name)

    const isMobileOS = useAppSelector(isMobileSel)

    const listRef = useRef<HTMLDivElement>(null)

    const onSelect = useCallback(
      (time: string) => {
        setValue(name, time, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
        setIsOpen(false)
      },
      [setValue, name],
    )

    const openDropdown = useCallback(() => {
      setIsOpen(true)

      const selectedTime = time ? moment(time, TIME_FORMAT) : moment()
      const preselectedTime = selectedTime
        .minutes(selectedTime.minutes() % 30 === 0 ? 0 : 30) // Round to nearest 30 minutes
        .format(TIME_FORMAT)

      const currentItem = listRef.current?.querySelector(
        `[data-time="${preselectedTime}"]`,
      )

      if (currentItem) {
        currentItem.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
        })
      }
    }, [time])

    const handleOnClick: MouseEventHandler<HTMLInputElement> =
      useCallback(() => {
        if (isSafari) {
          openDropdown()
        }
      }, [openDropdown])

    const handleOnFocus: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (!isSafari) {
          openDropdown()
        }
        onFocus?.(e)
      },
      [onFocus, openDropdown],
    )

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        onChange?.(e)
      },
      [onChange],
    )

    const onDropdownClose = useCallback(() => {
      setIsOpen(false)
    }, [])

    const clear: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> =
      useCallback(
        (e) => {
          e.preventDefault()
          e.stopPropagation()
          setValue(name, null, {
            shouldTouch: true,
            shouldValidate: true,
          })
        },
        [setValue, name],
      )

    const timeFormat = useRegionTimeFormat({ upperCaseAmPm: true })

    const formatTime = (time: string) => {
      const [hours, minutes] = time.split(':').map((s) => +s)
      return moment().hours(hours).minutes(minutes).format(timeFormat)
    }

    return (
      <>
        <div>
          <RHFInput
            {...rest}
            ref={inputRef}
            name={name}
            type="time"
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onClick={handleOnClick}
            startIcon={hasIcon ? <LineClockIcon /> : undefined}
            endIcon={
              time &&
              showClear && (
                <IconButton kind="tertiary" variant="ghost" onClick={clear}>
                  <LineTimesIcon colour="neutral-one" />
                </IconButton>
              )
            }
            data-testid={testIdPrefix && `${testIdPrefix}-input`}
          />
        </div>
        {!isMobileOS && (
          <DropdownList
            size="S"
            open={isOpen}
            triggerRef={inputRef}
            anchorRef={inputRef}
            onClose={onDropdownClose}
            placement="bottom-start"
            bodyRef={listRef}
            data-testid={testIdPrefix && `${testIdPrefix}-dropdown`}
          >
            {times.map((generatedTime) => (
              <ListItem
                title={formatTime(generatedTime)}
                key={generatedTime}
                selected={generatedTime === time}
                data-time={generatedTime}
                onClick={() => onSelect(generatedTime)}
              />
            ))}
          </DropdownList>
        )}
      </>
    )
  },
)

TimeInput.displayName = 'TimeInput'

export default TimeInput
